// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@400;700&family=Lobster&display=swap');

// Font Variables
$aleo: 'Aleo', serif;
$lobster: 'Lobster', sans-serif;

body {
    font-family: $aleo;
    font-weight: 400;
}

h1 {
    // color: red;
    font-family: $aleo;
    font-weight: 700;
}
